@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~axios-progress-bar/dist/nprogress.css';

/*
Template Name: Hyper - Responsive Bootstrap 5 Admin Dashboard
Version: 3.4.0
Author: CoderThemes
Email: support@coderthemes.com
File: Main Css File
*/

//Core files

@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "config/saas/variables";

@import "./config/saas/light-mode";
@import "./config/saas/dark-mode";
@import "config/saas/custom-variables";

@import "./node_modules/bootstrap/scss/bootstrap";

// Structure
@import "custom/structure/general";
@import "custom/structure/left-menu";
@import "custom/structure/topbar";
@import "custom/structure/right-sidebar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
// horizontal nav
@import "custom/structure/horizontal-nav";

//Fonts
@import "custom/fonts/nunito";

//Components
@import "custom/components/mixins";
@import "custom/components/accordions";
@import "custom/components/alert";
@import "custom/components/avatar";
@import "custom/components/breadcrumb";
@import "custom/components/buttons";
@import "custom/components/badge";
@import "custom/components/card";
@import "custom/components/color-select";
@import "custom/components/custom-forms";
@import "custom/components/dropdown";
@import "custom/components/docs";
@import "custom/components/forms";
@import "custom/components/modal";
@import "custom/components/nav";
@import "custom/components/pagination";
@import "custom/components/popover";
@import "custom/components/print";
@import "custom/components/progress";
@import "custom/components/reboot";
@import "custom/components/ribbons";
@import "custom/components/switch";
@import "custom/components/tables";
@import "custom/components/type";
@import "custom/components/utilities";
@import "custom/components/widgets";
@import "custom/components/social";
@import "custom/components/steps";
@import "custom/components/preloader";
@import "custom/components/hero";

@import "custom/components/DragDrop";

//Pages
@import "custom/pages/authentication";
@import "custom/pages/components-demo";
@import "custom/pages/error";
@import "custom/pages/faq";
@import "custom/pages/maintenance";
@import "custom/pages/tasks";
@import "custom/pages/email";
@import "custom/pages/timeline";

// Vendors
@import "vendor/daterangepicker";
@import "vendor/bootstrap-datepicker.min";
@import "vendor/jquery.toast.min";
@import "vendor/select2.min";
@import "vendor/jquery.toast.min";
@import "vendor/bootstrap-timepicker.min";
@import "vendor/jquery.bootstrap-touchspin.min";

// Plugins
@import "custom/plugins/apexcharts";
@import "custom/plugins/britechart";
@import "custom/plugins/calendar";
@import "custom/plugins/chartjs";
@import "custom/plugins/datatable";
@import "custom/plugins/daterange"; //react-range slider style
@import "custom/plugins/datepicker"; //react-date picker style
@import "custom/plugins/dragula";
@import "custom/plugins/dropzone";
@import "custom/plugins/form-wizard";
@import "custom/plugins/maps"; // react-vector map style
@import "custom/plugins/quill-editor";
@import "custom/plugins/select2";
@import "custom/plugins/simplebar";
@import "custom/plugins/toaster";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/bootstrap-timepicker";
@import "custom/plugins/simplemde";
@import "custom/plugins/typehead";
@import "custom/plugins/sparklines";
@import "custom/plugins/timepicker";
@import "custom/plugins/rateit";
@import "custom/plugins/rating";
@import "custom/plugins/react-select"; // react select style
@import "custom/plugins/react-table"; // react table style
@import "custom/plugins/ion-rangeslider";
@import "custom/plugins/jstree";
@import "custom/plugins/frappe-gantt";


@import "elements/FullCalendar";
@import "elements/DateTimePicker";
@import "elements/PersonSelect";
@import "pages/kitchen-monitor";
@import "pages/dashboard";
@import "pages/projectlog";
@import "pages/crewAttendanceOverview";

// colored header
@import "./topbar_colored";

.react-table.table-sm .sortable {
  &:before {
    top: 3px;
  }
  &:after {
    top: 9px;
  }
}

.react-table {
  thead {
    tr {
      th {
        position: relative;
        padding-right: unset;

        span.header-content {
          padding-right: 0.5rem;

          &.sortable {
            padding-right: 30px;
            padding-top: inherit;
            padding-bottom: inherit;
          }

          .selection-header-cell {
            height: 0;

            input[type="checkbox"] {
              position: relative;
            }
          }
        }
      }

      .resizer {
        display: inline-block;
        width: 9px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        touch-action: none;

        .resizer-separator {
          display: inline-block;
          width: 3px;
          height: 100%;
          margin-left: 3px;
        }

        &:hover {
          .resizer-separator {
            background: var(--ct-table-border-color);
          }
        }

        &.isResizing {
          .resizer-separator {
            background: var(--ct-table-border-color);
          }
        }
      }

      &:hover {
        .resizer-separator {
          background: var(--ct-table-border-color);
        }
      }
    }
  }

  tbody {
    tr {
      td {
        div.cell-content {
          &.resizable {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;

            &.space-eclipse {
              text-overflow: ellipsis " ...";
            }
          }
          &.line-wrap {
            white-space: normal;
          }
        }
      }
    }

  }
}

// Crude tool to keep react-table class from spilling
// into nested tables, e.g. DateTimePicker...
.react-table .react-table-stop {
  th {
    padding-left: unset;
    padding-right: unset;
  }
}

.DateRangePicker .react-datepicker {
  min-width: 438px;
}

.SelectInput {
  .react-select.react-select--is-disabled {
    .react-select__control.react-select__control--is-disabled {
      background-color: var(--ct-input-disabled-bg) !important;

      .react-select__indicators {
        svg {
          visibility: hidden;
        }
      }
    }
  }
}
input[type=number][disabled] {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.rdt {
  width: 100%;
}

.scroll-text-block {
  min-height: 400px;
  max-height: calc(100vh - 450px);
  overflow-y: scroll;
}

.mh-200px {
  max-height: 200px;
}

#nprogress .bar {
  height: 5px;
}

// Multiline options for react-select inputs
.SelectInput {
  .react-select {
    .react-select__option.rc-multiline {
      $rc-option-indent: 0.5 * $dropdown-item-padding-x;
      padding-left: ($dropdown-item-padding-x + $rc-option-indent) !important;
      text-indent: -($rc-option-indent);
      white-space: normal;
    }
  }
}

.border-workingDay input:not(:checked) {
  border: 2px solid #af0000;
}

.ChartPie,
.ChartBar {
  canvas {
    height: 350px !important;
    width: 100% !important;
  }
}

.leftside-menu,
.logo {
  width: 300px;
}

.content-page {
  margin-left: 300px;
}


.navbar-custom,
.footer {
  left: 300px;
}

body[data-leftbar-compact-mode=condensed]:not(.authentication-bg) .side-nav .side-nav-item:hover .side-nav-link {
  width: 300px;
}

.text-pre-wrap {
  white-space: pre-wrap;
}