$fc-event-divider-number: 3;
$fc-event-divider-thickness: 3px;
:root {
  --fc-event-resizer-thickness: calc(
    #{$fc-event-divider-thickness} * (#{$fc-event-divider-number} * 2 + 1)
  );
}

// main #3788d8
// food
// tuqoise #58FCEC
// orange #F08700
// tea-green #CAE7B9
// medium turqoise #75DDDD
// medium #84DCC6

//work
// spanish violet #4C2A85
// midnight #74226C
// dark purple #4B2142
// purple plum #9448BC

body {
  div.attendances.confirm-work-days {
    .fc {
      div.fc-timeline-body {
        tbody {
          tr {
            td:not(.fc-resource-group) {
              cursor: default;
            }
            .fc-resource {
              .fc-timeline-lane-frame {
                .fc-timeline-bg-harness {
                  .fc-bg-event {
                    display: none;

                  }
                }

                .fc-event {
                  cursor: default;
                  &:not(.event-work) {
                    background-color: $secondary;
                  }
                  .fc-event-main {
                    .fc-event-confirm-wrapper {
                      &:hover .confirm-multiple {
                        display: block;
                      }
                      .confirm-single {
                        span {
                          &:not(.future, .accounting) {
                            cursor: pointer;
                          }

                          &.not-confirmed:not(.accounting) {
                            color: var(--ct-card-bg);

                            &:hover {
                              opacity: 1;
                            }
                          }
                        }
                      }
                    }
                    .fc-event-toolbar-wrapper::before {
                      display: none;
                    }
                  }

                  .fc-event-resizer {
                    display: none;
                  }

                  .fc-event-resizer-start-flag,
                  .fc-event-resizer-end-flag,
                  .fc-event-resizer-start-stripe,
                  .fc-event-resizer-end-stripe,
                  .fc-event-resizer-start-shade,
                  .fc-event-resizer-end-shade {
                    display: none;
                  }

                  .event-food-tooltip {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@function setMode($mode) {
  @if $mode == dark {
    @return '[data-layout-color="dark"] ';
  } @else {
    @return '';
  }
}

$fc-event-colors: (
        'attendance': #3788d8,
        'work': #9b2e91,
        'food': #4da167,
);

@each $name, $color in $fc-event-colors {
  $modes: (
    dark: darken($color, 15%),
    light: lighten($color, 15%),
  );
  @each $mode, $func in $modes {
    $color-hover: $func;

    body#{setMode($mode)} {
      div.attendances:not(.confirm-work-days) {
        .fc {
          div.fc-timeline-body {
            tbody {
              tr {
                .fc-resource {
                  .fc-timeline-lane-frame {
                    .fc-event {
                      &.event-#{$name} {
                        cursor: move;
                        &:hover {
                          background-color: $color-hover;
                        }
                      }
                      .fc-event-main {

                        &:hover {
                          .fc-event-toolbar-wrapper::before {
                            z-index: 11;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .fc-sticky-top.sticky-top {
        @if $mode == dark {
          background: var(--ct-card-bg);
        } @else {
          background: #ffffff;
        }
        z-index: 4;
        top: 55px;
      }

      .fc {
        .fc-header-toolbar {
          position: sticky;
          top:calc( 58px + 55px);
          min-height: 47px;
          z-index: 3;
          @if $mode == dark {
            background: var(--ct-card-bg);
          } @else {
            background: #ffffff;
          }
          &.fc-toolbar {
            margin: unset !important;
            padding: 6px 0 5px 0 !important;
          }
          .fc-toolbar-title {
            display: inline;
            vertical-align: middle;
          }
          .fc-month-button {
            @if $mode == dark {
              color: #ffffff;
            } @else {
              color: #000000;
            }
            background-color: transparent !important;
            border-color: transparent !important;
            cursor: not-allowed;
            pointer-events: none;
            font-size: 1.5rem;
            min-width: 144px;
            margin-left: 12px !important;
            box-shadow: none;
          }

          .fc-month-button:hover {
            pointer-events: none;
            cursor: not-allowed;
          }
        }
        .fc-scrollgrid-section-sticky > * {
          @if $mode == dark {
            background: var(--ct-card-bg);
          }
          top: calc(47px + 60px + 57px) !important;
          border-bottom-width: 1px;

          .fc-scroller-harness {
            @if $mode == dark {
              border-top: #6c757d 1px solid;
            } @else {
              border-top: #dee2e6 1px solid;
            }
            margin-top: -1px;

          }
        }

        // time slots header
        div.fc-timeline-header {
          tbody {
            .fc-timeline-slot.fc-day-summary {
              a {
                font-weight: 400;
              }
            }
            .fc-timeline-slot.fc-day-isProject {
              @if $mode == dark {
                background-color: rgba(255,255,255,0.1);
              } @else {
                background-color: rgba(0,0,0,0.08);
              }

            }
            .fc-timeline-slot-frame {
              height: unset !important;
              width: 31px;

              .fc-timeline-slot-cushion {
                white-space: pre !important;
                width: 31px;
              }
            }
          }
        }

        div.fc-timeline-slots {
          .fc-timeline-slot {
            width: 31px;
          }
        }

        // resource headers
        table.fc-datagrid-header {
          thead {
            .fc-datagrid-cell:not(.fc-resource-group):not(.fc-resource) {
              .fc-datagrid-cell-frame {
                .fc-datagrid-cell-cushion {
                  width: 100%;
                }
              }
            }
          }
        }
        table.fc-datagrid-body {
          tbody {
            .fc-resource-group {
              padding-bottom: 0.5px;
              .fc-datagrid-cell-frame {
                height: 26px !important;
                .fc-datagrid-cell-cushion {
                  padding-top: 1px;
                  padding-bottom: 1px;
                }
              }
            }
            .fc-resource {
              .fc-datagrid-cell-frame,
              .fc-datagrid-cell-cushion {
                height: 40px !important;

                .fc-icon {
                  display: none;
                }
                .fc-datagrid-expander-placeholder {
                  display: none !important;
                }
              }
            }
          }
        }

        .fc-resource-timeline-divider {
          @if $mode == dark {
            background-color: #6c757d;
          }
        }

        // resource data
        div.fc-timeline-body {
          tbody {
            .fc-timeline-slot.fc-day-isProject {
              @if $mode == dark {
                background-color: rgba(255,255,255,0.1);
              } @else {
                background-color: rgba(0,0,0,0.08);
              }
            }
            tr {
              position: relative;
              z-index: 1;
              .fc-resource-group {
                @if $mode == dark {
                  background-color: rgb(62, 62, 62) !important;
                } @else {
                  background-color: rgb(225, 225, 225) !important;
                }

                z-index: 1;

                div:not(.fc-preview-line) {
                  height: 26px !important;
                  @if $mode == dark {
                    border-bottom: #6c757d 1px solid;
                  } @else {
                    border-bottom: #dee2e6 1px solid;
                  }
                }

                .fc-preview-line {
                  position: absolute;
                  height: 5px;
                }

                .fc-preview-line.event-#{$name} {
                  background-color: $color;
                  @if $name == 'attendance' {
                    top: 2px;
                  } @else if $name == 'work' {
                    top: 10px;
                  } @else if $name == 'food' {
                    top: 18px;
                  } @else {
                    top: 0;
                  }
                }
              }

              td:not(.fc-resource-group) {
                cursor: cell;
              }

              .fc-resource {
                .fc-timeline-lane-frame {
                  height: 40px !important;
                  margin-top: -0.5px;
                  margin-bottom: -0.5px;

                  .fc-timeline-event-harness {
                    background-color: red !important;
                    .fc-timeline-event {
                      margin: 0 !important;
                      padding: 0 !important;

                    }
                  }

                  .fc-timeline-bg-harness {
                    .fc-bg-event {
                      @if $mode == dark {
                        background: repeating-linear-gradient(
                                        135deg,
                                        $gray-800,
                                        $gray-800 7px,
                                        #6c757d 7px,
                                        #6c757d 8.5px
                        );
                        border-right: #6c757d 1px solid;
                      } @else {
                        background: repeating-linear-gradient(
                                        135deg,
                                        $gray-200,
                                        $gray-200 7px,
                                        #dee2e6 7px,
                                        #dee2e6 8.5px
                        );
                      }
                      opacity: 1;
                      cursor: not-allowed;


                      //background: unset;
                    }
                  }

                  // attendance, working days and food
                  .fc-event.event-#{$name} {
                    background-color: $color;
                    border: none;

                    .fc-event-main {
                      z-index: unset !important;

                      .fc-event-confirm-wrapper {
                        position: absolute;
                        width: 100%;
                        bottom: 0;
                        text-align: left;
                        .confirm-multiple {
                          display: none;
                          margin-left: 11px;
                          margin-bottom: 8px;
                          span {
                            font-size: 24px;
                            padding: 4px 9px;
                            color: $color;
                            border-radius: 0.15rem;
                            cursor: pointer;
                            &:hover {
                              color: white;
                              background-color: $color;
                            }
                          }
                        }
                        .confirm-single {
                          width: 100%;
                          height: 32px;
                          display: flex;
                          text-align: center;
                          span {
                            font-size: 20px;
                            width: 31px;
                            z-index: 10;

                            @if $mode == dark {
                              color: lighten($color, 15%)
                            } @else {
                              color: darken($color, 15%)
                            }

                            &.future, &.not-confirmed {
                              opacity: 0;
                            }
                          }
                        }
                      }

                      .fc-event-toolbar-wrapper {
                        width: calc(100% + 3px);
                        padding-top: 5px;
                        padding-bottom: 5px;
                        margin: -5px 0 -5px -1px;
                        -webkit-transition: all 100ms linear;
                        -moz-transition: all 100ms linear;
                        -o-transition: all 100ms linear;
                        transition: all 100ms linear;
                      }

                      .fc-event-toolbar-wrapper:hover {
                        //background-color: $color-hover;
                      }

                      .fc-event-toolbar-wrapper::before {
                        color: $color;

                        $fc-event-icon-size: 25px;
                        $fc-event-icon-width: 0.75 * $fc-event-icon-size;
                        $fc-event-icon-height: 1.5 * $fc-event-icon-size;

                        content: '\f142\f142';
                        font-family: 'Font Awesome 5 Free';
                        font-weight: 900;
                        position: absolute;
                        transform: rotate(90deg);
                        left: 50%;
                        margin-left: -#{$fc-event-icon-width / 2};
                        top: 50%;
                        margin-top: -#{$fc-event-icon-height / 2};
                        font-size: $fc-event-icon-size;
                      }

                      .fc-event-toolbar-wrapper:hover::before {
                        @if $mode == dark {
                          color: $color;
                        } @else {
                          color: $color;
                        }
                      }

                      .fc-event-toolbar-wrapper {
                        .fc-event-toolbar {
                          text-align: right;
                          display: flex;
                          justify-content: end;
                          min-height: 32px;

                          .fc-event-toolbar-buffer {
                            height: 100%;
                            width: min(50% - 34px, 34px);
                          }

                          .btn {
                            .fas {
                              position: relative;
                              z-index: 10;
                            }
                          }
                        }
                      }
                    }

                    .fc-event-resizer {
                      display: block !important;
                    }

                    .fc-event-resizer-start-flag,
                    .fc-event-resizer-end-flag,
                    .fc-event-resizer-start-stripe,
                    .fc-event-resizer-end-stripe,
                    .fc-event-resizer-start-shade,
                    .fc-event-resizer-end-shade {
                      position: absolute;
                      height: 100%;
                      pointer-events: none;
                    }

                    .fc-event-resizer-start-flag,
                    .fc-event-resizer-end-flag,
                    .fc-event-resizer-start-stripe,
                    .fc-event-resizer-end-stripe {
                      width: 3px;
                    }

                    .fc-event-resizer-start-stripe,
                    .fc-event-resizer-end-stripe,
                    .fc-event-resizer-start-shade,
                    .fc-event-resizer-end-shade {
                      opacity: 0;
                      -webkit-transition: opacity 100ms linear;
                      -moz-transition: opacity 100ms linear;
                      -o-transition: opacity 100ms linear;
                      transition: opacity 100ms linear;
                    }

                    .fc-event-resizer-start-flag,
                    .fc-event-resizer-end-flag {
                      background-color: $color;
                      -webkit-transition: background-color 100ms linear;
                      -moz-transition: background-color 100ms linear;
                      -o-transition: background-color 100ms linear;
                      transition: background-color 100ms linear;
                    }

                    .fc-event-resizer-start-stripe,
                    .fc-event-resizer-end-stripe {
                      @if $mode == dark {
                        background-color: $gray-800;
                      } @else {
                        background-color: $gray-200;
                      }
                      z-index: 1;
                    }

                    .fc-event-resizer-start-shade,
                    .fc-event-resizer-end-shade {
                      width: min(40%, 250px);
                    }

                    .fc-event-resizer-start-flag {
                      left: -7px
                    }

                    .fc-event-resizer-end-flag {
                      right: -7px
                    }

                    .fc-event-resizer-start-stripe {
                      left: 2px;
                    }

                    .fc-event-resizer-end-stripe {
                      right: 2px;
                    }

                    .fc-event-resizer-start-shade {
                      background: linear-gradient(90deg, $color-hover, $color);
                      left: -1px;
                    }

                    .fc-event-resizer-end-shade {
                      background: linear-gradient(270deg, $color-hover, $color);
                      right: -1px;
                    }

                    .fc-event-main:hover ~ .fc-event-resizer-start-flag,
                    .fc-event-main:hover ~ .fc-event-resizer-end-flag,
                    .fc-event-resizer-start:hover ~ .fc-event-resizer-start-flag,
                    .fc-event-resizer-end:hover ~ .fc-event-resizer-end-flag {
                      background-color: $color-hover;
                    }

                    .fc-event-resizer-start:hover ~ .fc-event-resizer-start-stripe,
                    .fc-event-resizer-start:hover ~ .fc-event-resizer-start-shade,
                    .fc-event-resizer-end:hover ~ .fc-event-resizer-end-stripe,
                    .fc-event-resizer-end:hover ~ .fc-event-resizer-end-shade {
                      opacity: 1;
                    }
                  }

                  .fc-event-resizing.event-#{$name} {
                    opacity: 0.75;
                    pointer-events: none;

                    .fc-event-resizer-start-flag,
                    .fc-event-resizer-end-flag {
                      background-color: $color-hover;
                    }

                    .fc-event-resizer-start-stripe,
                    .fc-event-resizer-start-shade,
                    .fc-event-resizer-end-stripe,
                    .fc-event-resizer-end-shade {
                      opacity: 1 !important;;
                    }
                  }


                  .fc-event.event-food {
                    @if $mode == dark {
                      border-bottom: $gray-800 solid 2.25px;
                    } @else {
                      border-bottom: $gray-200 solid 2.25px;
                    }

                    .event-food-tooltip {
                      @if $mode == dark {
                        background-color: #212529;
                        border: #6c757d 1px solid;
                      } @else {
                        background-color: #ffffff;
                        border: #dee2e6 1px solid;
                      }
                      white-space: nowrap;
                      position: absolute;
                      visibility: hidden;
                      top: -35px;
                      padding: 2px 5px;
                      border-radius: 0.3rem;
                      pointer-events: none;
                      cursor: pointer;
                      text-align: center;
                      opacity: 0;
                      -webkit-transition: opacity 100ms linear;
                      -moz-transition: opacity 100ms linear;
                      -o-transition: opacity 100ms linear;
                      transition: opacity 100ms linear;

                    }
                  }

                  .fc-event.event-food:hover {
                    .event-food-tooltip {
                      visibility: visible;
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .fc-scrollgrid-section-footer {
        th:first-child > * {
          visibility: hidden;
        }
        th:not(:first-child) {
          @if $mode == dark {
            border-bottom: #6c757d solid 1px;
          } @else {
            border-bottom: #dee2e6 solid 1px;
          }

          .fc-scroller {
            @if $mode == dark {
              scrollbar-color: $gray-500 $gray-800;
            } @else {
              scrollbar-color: $gray-500 $gray-200;
            }
          }
          .fc-scroller:hover {
            @if $mode == dark {
              scrollbar-color: $gray-900 $gray-400;
            } @else {
              scrollbar-color: $gray-100 $gray-700;
            }
          }
        }
        .fc-resource-timeline-divider {
          @if $mode == dark {
            background-color: #6c757d !important;
          } @else {
            background-color: #dee2e6;
          }
        }
      }
      .beside-mouse-warning {
        @if $mode == dark {
          background-color: #212529;
          border: #6c757d 1px solid;
        } @else {
          background-color: #ffffff;
          border: #dee2e6 1px solid;
        }
        color: #ffc107 !important;
        white-space: nowrap;
        padding: 2px 5px;
        border-radius: 0.3rem;
        pointer-events: none;
        cursor: pointer;
        text-align: center;
      }
    }
  }
}
